import * as React from "react";
import { ThemeImmo } from "../lib/types";
import LinkButton from "./button";
import HeadTitle from "./headtitle";
import LinkText from "./link";
import { useState } from "react";
import Icon from "./icon";
import { AnimatePresence, motion } from "framer-motion";
import { Link } from "gatsby";
import { linkWithSlash } from "../lib/functions";

export interface ThemeImmoCardProps {
  theme: ThemeImmo;
  className?: string;
}

const ThemeImmoCard: React.FC<ThemeImmoCardProps> = ({
  theme,
  className = "",
}) => {
  const [cardOpen, setCardOpen] = useState(false);

  const containerAnimationOptions = {
    hidden: {
      opacity: 0,
      height: 0,
    },
    show: {
      opacity: 1,
      height: "auto",
    },
  };

  const itemAnimationOptions = {
    hidden: {
      opacity: 0,
    },
    show: { opacity: 1 },
  };

  const subjectsToShow = [...theme.subjects, theme].filter(
    ({ articles }) => articles.length > 0
  );

  return (
    <div
      tabIndex={0}
      className={`${className} bg-white rounded-md shadow-center
          relative
          pl-6
          pt-6
          pr-6
          cursor-pointer
          flex flex-col
          duration-300 ease-in-out 
           group
            md:hover:scale-110 
            hover:scale-105 
            hover:shadow-large-center 
            md:active:scale-110
            active:scale-105
            active:shadow-large-center
          `}
    >
      <Link
        to={linkWithSlash(`/pages/guide-immo/${theme.SEO.slug}`)}
        className={"space-y-4 pb-10"}
      >
        <HeadTitle
          as={"h2"}
          className={"italic uppercase"}
          href={linkWithSlash(`/pages/guide-immo/${theme.SEO.slug}`)}
          label={theme.name}
        />

        <div className={"grid gap-4 grid-cols-3 relative"}>
          <div className={"text-sm font-light col-span-2"}>
            {theme.SEO.description}
          </div>
          {/* Illustration */}
          {theme.illustration && (
            <img
              className={`absolute -bottom-6 h-30 -right-6`}
              src={theme.illustration.localFile.publicURL}
              alt={`Illustration ${theme.name}`}
            />
          )}
        </div>
      </Link>

      <AnimatePresence>
        {cardOpen && (
          <motion.ul
            className={"flex flex-col space-y-4"}
            variants={containerAnimationOptions}
            initial="hidden"
            exit="hidden"
            animate="show"
            key={"ul"}
          >
            <motion.div
              className={"self-start mt-4"}
              variants={itemAnimationOptions}
              transition={{
                duration: 0.1,
              }}
            >
              <LinkButton
                size={"sm"}
                href={linkWithSlash(`/pages/guide-immo/${theme.SEO.slug}`)}
                label={"Accéder à la thématique"}
              />
            </motion.div>

            {subjectsToShow.map((subject, index) => (
              <motion.li
                variants={itemAnimationOptions}
                transition={{
                  duration: 0.1,
                }}
                key={subject.id}
                className={`grid grid-cols-10 gap-2 w-full ${
                  index === subjectsToShow.length - 1 && "!mb-8"
                }`}
              >
                <Icon
                  icon={"arrow-right"}
                  className={"text-secondary-100 h-6 w-6 col-span-1"}
                />
                <LinkText
                  href={
                    linkWithSlash(`/pages/guide-immo/${theme.SEO.slug}`) +
                    `#${subject.SEO.slug}`
                  }
                  label={subject.name}
                  className={"col-span-9"}
                />
              </motion.li>
            ))}
          </motion.ul>
        )}
      </AnimatePresence>

      <button
        aria-label={"Dérouler les sujets du terme"}
        className={`flex items-center w-full
          h-10
          justify-center bg-gradient-to-t from-gray-200/50
          rounded-b-md
          absolute bottom-0 left-0 right-0
        `}
        onClick={() => setCardOpen(!cardOpen)}
      >
        <Icon
          icon={!cardOpen ? "arrow-down" : "arrow-up"}
          className={"h-6 text-secondary-100"}
        />
      </button>
    </div>
  );
};

export default ThemeImmoCard;
