import { graphql } from "gatsby";
import * as React from "react";

// components
import CategoryHeader from "../../../components/categoryheader";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ThemeImmoCard from "../../../components/theme-immo-card";

// types & interfaces
import type { Category, PageProps, ThemeImmo } from "../../../lib/types";

type GuideImmoPageProps = Category & {
  themes: Array<ThemeImmo>;
};

const GuideImmo: React.FC<PageProps<GuideImmoPageProps>> = ({
  data: { page: guide },
  location: { pathname },
}) => {
  return (
    <>
      <Seo
        {...guide.SEO}
        image={guide.image?.localFile.publicURL}
        pathname={pathname}
      />
      <Layout active="pages/guide-immo">
        <main className={"mb-48 mt-15 sm:mb-44 lg:mt-20"}>
          <CategoryHeader title={guide.name} cover={guide.image} />

          <section className="px-5 pt-0 pb-0 mt-8 md:pt-4 lg:pt-8 md:px-15">
            <p>{guide.SEO.description}</p>

            <div
              className={`mt-10 grid grid-cols-1
              gap-5 
              sm:grid-cols-2 
              lg:grid-cols-3
              md:gap-10
              xl:gap-15
              xxl:gap-20
              max-w-[1200px]
              m-auto
              `}
            >
              {guide.themes.map((theme, index) => (
                <ThemeImmoCard
                  theme={theme}
                  className={"self-start"}
                  key={index}
                />
              ))}
            </div>
          </section>
        </main>
      </Layout>
    </>
  );
};
export default GuideImmo;

export const query = graphql`
  query GuideImmo {
    page: strapiCategorytree(name: { eq: "Guide de l'achat immobilier" }) {
      id: strapiId
      name
      image {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      SEO {
        slug
        title
        description
      }
      themes: subcategories {
        id
        name
        articles {
          id
        }
        illustration {
          localFile {
            publicURL
          }
        }

        SEO {
          slug
          description
        }

        subjects: subcategories {
          id
          name
          SEO {
            slug
          }

          articles {
            id
          }
        }
      }
    }
  }
`;
